import { type Branch, branchNameMap } from "../../core/schema/Branch.js";
import type { Locale } from "../../core/schema/Locale.js";
import type { B2cOrderCreateInput, B2cOrderEditInput, Order } from "../../core/schema/Order.js";
import type { OrderProduct } from "../../core/schema/OrderProduct.js";
import { type PaymentType, paymentTypeNameMap } from "../../core/schema/PaymentType.js";
import { PriceLevel } from "../../core/schema/PriceLevel.js";
import type { ProductForCustomer } from "../../core/schema/Product.js";
import { type ProductSize, productSizeNameMap } from "../../core/schema/ProductSize.js";
import { createEmptyOptions } from "../../core/schema/utils/createEmptyOptions.js";
import { getPriceOfOrder } from "../../core/schema/utils/getPriceOfOrder.js";
import { getPriceOfOrderProduct } from "../../core/schema/utils/getPriceOfOrderProduct.js";
import { transformProductToOrderProduct } from "../../core/schema/utils/transformProductToOrderProduct.js";
import { roundToPlaces } from "../../core/utils/roundToPlaces.js";
import type { OrderAfterOverview } from "./stores/cart/Cart.js";

declare const gtag: (...args: unknown[]) => void;

interface DynamicRemarketingEvent {
	event: string;
	ecommerce?: Record<string, unknown>;
	[other: string]: unknown;
}

interface Item {
	item_name: string;
	item_id: string;
	item_variant?: string;
	price: number;
	quantity: number;
}

function getItem(product: OrderProduct, locale: Locale): Item {
	const size = product.options?.size;
	const price = getPriceOfOrderProduct({ ...product, quantity: 1 });

	return {
		item_name: product.product.name[locale],
		item_id: product.product.id,
		...(size !== undefined ? { item_variant: productSizeNameMap[size][locale] } : {}),
		price: roundToPlaces(price.withoutTax, 2),
		quantity: product.quantity,
	};
}

export function reportProductDetail(product: ProductForCustomer, locale: Locale, size: ProductSize): void {
	const defaultOptions = createEmptyOptions(size);
	const orderProduct = transformProductToOrderProduct(crypto.randomUUID(), product, 1, defaultOptions);
	const price = getPriceOfOrderProduct(orderProduct);

	const event: DynamicRemarketingEvent = {
		event: "view_item",
		ecommerce: {
			currency: "CZK",
			value: roundToPlaces(price.withoutTax, 2),
			items: [getItem(orderProduct, locale)],
		},
	};

	gtag("event", event.event, event.ecommerce);
}

export function reportAddedToCart(product: OrderProduct, locale: Locale, itemQuantity?: number): void {
	const productOfQuantity = { ...product, quantity: itemQuantity ?? product.quantity };
	const price = getPriceOfOrderProduct(productOfQuantity);
	const event: DynamicRemarketingEvent = {
		event: "add_to_cart",
		ecommerce: {
			currency: "CZK",
			value: roundToPlaces(price.withoutTax, 2),
			items: [getItem(productOfQuantity, locale)],
		},
	};

	gtag("event", event.event, event.ecommerce);
}

export function reportRemovedFromCart(product: OrderProduct, locale: Locale, itemQuantity?: number): void {
	const quantity = itemQuantity ?? product.quantity;
	const productOfQuantity = { ...product, quantity };
	const price = getPriceOfOrderProduct(productOfQuantity);
	const event: DynamicRemarketingEvent = {
		event: "remove_from_cart",
		ecommerce: {
			currency: "CZK",
			value: roundToPlaces(price.withoutTax, 2),
			items: [getItem(productOfQuantity, locale)],
		},
	};

	gtag("event", event.event, event.ecommerce);
}

export function reportCheckoutBegan(
	order: Pick<Order | B2cOrderCreateInput | B2cOrderEditInput, "products">,
	locale: Locale,
): void {
	const price = getPriceOfOrder(order, PriceLevel.Basic);
	const event: DynamicRemarketingEvent = {
		event: "begin_checkout",
		ecommerce: {
			currency: "CZK",
			value: roundToPlaces(price.totalPrice.withoutTax, 2),
			items: Object.values(order.products).map((item) => getItem(item, locale)),
		},
	};

	gtag("event", event.event, event.ecommerce);
}

export function onPaymentTypeChanged(order: OrderAfterOverview, locale: Locale, paymentType: PaymentType): void {
	const price = getPriceOfOrder(order, PriceLevel.Basic);
	const event: DynamicRemarketingEvent = {
		event: "add_payment_info",
		ecommerce: {
			currency: "CZK",
			value: roundToPlaces(price.totalPrice.withoutTax, 2),
			payment_type: paymentTypeNameMap[paymentType],
			items: Object.values(order.products).map((item) => getItem(item, locale)),
		},
	};

	gtag("event", event.event, event.ecommerce);
}

export function onPickUpBranchChanged(order: OrderAfterOverview, locale: Locale, shippingType: Branch): void {
	const price = getPriceOfOrder(order, PriceLevel.Basic);
	const event: DynamicRemarketingEvent = {
		event: "add_shipping_info",
		ecommerce: {
			currency: "CZK",
			value: roundToPlaces(price.totalPrice.withoutTax, 2),
			shipping_tier: branchNameMap[shippingType],
			items: Object.values(order.products).map((item) => getItem(item, locale)),
		},
	};

	gtag("event", event.event, event.ecommerce);
}

export function reportPurchase(order: Order, locale: Locale): void {
	const priceWithTax = order.price.totalPrice.withTax;
	const priceWithoutTax = order.price.totalPrice.withoutTax;
	const event: DynamicRemarketingEvent = {
		event: "purchase",
		ecommerce: {
			currency: "CZK",
			value: roundToPlaces(priceWithoutTax, 2),
			tax: roundToPlaces(priceWithTax - priceWithoutTax, 2),
			shipping: roundToPlaces(order.price.deliveryPrice, 2),
			transaction_id: order.goPayData.id ?? "",
			items: Object.values(order.products).map((item) => getItem(item, locale)),
		},
	};

	gtag("event", event.event, event.ecommerce);
}
